<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="computedRelevancy"
      :loading="loading"
      loader-height="2"
      :items-per-page="-1"
      :no-data-text="'noDataAvailable'|localize"
      hide-default-footer
    >
      <template v-slot:item.transport_mode="{ item }">
        <div class="d-flex align-center">
          <v-tooltip top color="black">
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" color="silver-chalice">{{ TRANSPORT_ICONS[item.transport_mode] }}</v-icon>
            </template>
            <span>{{ item.transport_mode|localize }}</span>
          </v-tooltip>
          <template v-if="item['black_list']">
            <v-tooltip right color="black">
              <template v-slot:activator="{ on, attrs }">
                <div class="d-inline-flex align-center ml-2" v-bind="attrs" v-on="on">
                  <v-icon color="error">mdi-account-cancel</v-icon>
                  <span class="error--text text-caption"> {{ item['black_list'].length }}</span>
                </div>
              </template>
              <template>
                <div class="py-1">
                  <h5 class="text-subtitle-2 mb-1">{{ 'blockedClients'|localize }}</h5>
                  <ul class="px-2">
                    <li v-for="(client, index) in item['black_list']" :key="index">{{ client['name'] }} <i class="text-caption">(ID: {{client['id']}})</i></li>
                  </ul>
                </div>
              </template>
            </v-tooltip>
          </template>
        </div>
      </template>
      <template v-slot:item.type_of_goods="{ item }">
        <div class="py-2">
          <v-chip x-small label v-for="type in item['type_of_goods']" :key="type" class="mr-1 px-1">{{ goodTypesList.find(x => x.key === type).value }}</v-chip>
        </div>
      </template>
      <template v-slot:item.delivery_terms="{ item }">
        <div class="py-2">
          <v-chip x-small label v-for="value in item['delivery_terms']" :key="value" class="mr-1 px-1">{{ value }}</v-chip>
        </div>
      </template>
      <template v-slot:item.country_from="{ item }">
        <span v-for="(country, index) in item['country_from']" :key="country.code">
          <template v-if="index < 2"><span v-if="index > 0">, </span>{{ country.name }}</template>
          <span v-if="index === 2" class="grey--text text-caption ml-1">+{{ item['country_from'].length - 2 }}</span>
        </span>
      </template>
      <template v-slot:item.country_to="{ item }">
        <span v-for="(country, index) in item['country_to']" :key="country.code">
          <template v-if="index < 2"><span v-if="index > 0">, </span>{{ country.name }}</template>
          <span v-if="index === 2" class="grey--text text-caption ml-1">+{{ item['country_to'].length - 2 }}</span>
        </span>
      </template>
      <template v-slot:item.special_requirements="{ item }">
        <v-chip x-small label v-for="requirement in item['special_requirements']" :key="requirement" class="mr-1 px-1">{{ requirement|localize }}</v-chip>
      </template>
      <template v-slot:item.receiver="{ item }">
        <v-chip x-small label v-for="receiverItem in item['receiver']" :key="receiverItem" class="mr-1 px-1">{{ receiverItem|localize }}</v-chip>
      </template>
      <template v-slot:item.settings="{ item }">
        <v-progress-circular
          v-if="deleteQueue.includes(item.id)"
          indeterminate
          :width="1"
          :size="20"
        />
        <v-menu v-else left transition="slide-y-transition">
          <template v-slot:activator="{ on, attrs }">
            <v-avatar v-bind="attrs" v-on="on" size="45" tile>
              <v-icon size="20">mdi-dots-vertical</v-icon>
            </v-avatar>
          </template>
          <v-list>
            <v-list-item link @click="editRelevancy(item.id)">
              <v-list-item-title>{{ 'edit'|localize }}</v-list-item-title>
            </v-list-item>
            <v-list-item link @click="copyRelevancy(item.id)">
              <v-list-item-title>{{ 'copy'|localize }}</v-list-item-title>
            </v-list-item>
            <v-list-item link @click="openDeleteRelevancy(item)">
              <v-list-item-title>{{ 'delete'|localize }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>
    <SimpleDialog
      ref="deleteRelevancyDialog"
      :title="'deleteRelevancy'|localize"
      :text="'deleteRelevancyText'|localize"
      :accept-text="'delete'|localize"
      :decline-text="'back'|localize"
      @decline="cancelDeleteRelevancy"
      @accept="deleteRelevancy"
    />
  </div>
</template>

<script>
  import localize from '@/filters/localize';
  import {TRANSPORT_ICONS} from '@/enums/transportMode';
  import methods from '@/helpers/methods';
  import api from '@/api/api';
  import SimpleDialog from '@/components/common/SimpleDialog.vue';

  export default {
    name: 'AllUserRelevancy',
    components: {SimpleDialog},

    data() {
      return {
        TRANSPORT_ICONS,
        company_id: this.$router.currentRoute.query.company_id,
        user_id: this.$router.currentRoute.query.user_id,
        loading: false,
        deleteRelevancyId: null,
        deleteQueue: [],
        relevancy: [],
        clients: []
      };
    },

    mounted() {
      this.getRelevancy()
    },

    computed: {
      goodTypesList() {
        return this.$store.getters.goodTypesSorted.all;
      },
      headers() {
        return [
          {text: localize('transportMode'), value: 'transport_mode', sortable: false, width: 130},
          {text: localize('typeOfGoods'), value: 'type_of_goods', sortable: false, width: 130},
          {text: localize('deliveryTerms'), value: 'delivery_terms', sortable: false, width: 130},
          {text: localize('countryFrom'), value: 'country_from', sortable: false, width: '20%'},
          {text: localize('countryTo'), value: 'country_to', sortable: false, width: '20%'},
          {text: localize('specialRequirements'), value: 'special_requirements', sortable: false, width: '20%'},
          {text: localize('receiver'), value: 'receiver', sortable: false, width: '20%'},
          {text: '', value: 'settings', sortable: false, width: 80, align: 'center'},
        ];
      },
      computedRelevancy() {
        let localRelevancy = JSON.parse(JSON.stringify(this.relevancy));
        localRelevancy.forEach((row) => {
          if (row['blocked_clients_custno']?.length) {
            row['black_list'] = this.clients.filter(client => row['blocked_clients_custno'].includes(client['id']));
          }
        });
        return localRelevancy;
      }
    },

    methods: {
      editRelevancy(id) {
        this.$router.push({name: 'user-relevancy', query: {company_id: this.company_id, user_id: this.user_id, relevancy_id: id}});
      },
      copyRelevancy(id) {
        this.$router.push({name: 'user-relevancy', query: {company_id: this.company_id, user_id: this.user_id, copy_id: id}});
      },
      openDeleteRelevancy(item) {
        this.$refs.deleteRelevancyDialog.open();
        this.deleteRelevancyId = item.id;
      },
      cancelDeleteRelevancy() {
        this.deleteRelevancyId = null;
      },
      async deleteRelevancy() {
        let itemId = this.deleteRelevancyId;
        this.deleteRelevancyId = null;
        this.deleteQueue.push(itemId);
        try {
          await api.deleteUserRelevancy(itemId, this.company_id, this.user_id);
          let relevancyData = await api.getAllUserRelevancy(this.company_id, this.user_id);
          this.relevancy = relevancyData.data;
          this.deleteQueue = this.deleteQueue.filter(queueId => queueId !== itemId)
        } catch (e) {
          methods.showGlobalSnackbar('error', methods.getErrors(e.response));
        }
      },
      async getRelevancy() {
        if (!this.user_id) return;
        try {
          this.loading = true;
          let relevancyData = await api.getAllUserRelevancy(this.company_id, this.user_id);
          if (!this.clients.length) {
            let clientsData = await api.getClients({ paginate: 0 });
            this.clients = clientsData.data.data;
          }
          this.relevancy = relevancyData.data;
          this.loading = false;
        } catch (e) {
          this.loading = false;
        }
      }
    }
  };
</script>
