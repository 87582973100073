<template>
  <v-container class="pt-7 pb-11">
    <v-progress-linear v-if="getLoading" height="2" indeterminate/>
    <template v-else>
      <v-row class="mt-0 mb-4">
        <v-col>
          <v-breadcrumbs :items="breadcrumbsItems" divider="/" class="pa-0"/>
        </v-col>
      </v-row>
      <v-row class="mb-8" justify="space-between" align="center">
        <v-col cols="auto" class="text-left">
          <h1 class="text-h4">{{ pageHeader }}</h1>
        </v-col>
        <v-col v-if="user_id" cols="auto" class="py-0">
          <v-btn :to="{name: 'user-relevancy', query: {company_id: this.company_id, user_id: this.user_id}}" color="primary" depressed rounded>
            <v-icon size="25" left class="mr-3">mdi-plus</v-icon>
            {{ 'createRelevancy'|localize }}
          </v-btn>
        </v-col>
      </v-row>
      <div class="white elevation-2 rounded">
        <v-row v-if="user_id" class="my-0">
          <v-col class="py-0">
            <v-tabs v-model="tab" @change="$router.replace({query: $router.currentRoute.query, hash: tab})" background-color="transparent" class="grey--text text--lighten-1">
              <v-tab :ripple="false" href="#general" class="text-none">
                {{ 'general'|localize }}
              </v-tab>
              <v-tab :ripple="false" href="#relevancy" class="text-none">
                {{ 'relevancy'|localize }}
              </v-tab>
            </v-tabs>
            <v-divider/>
          </v-col>
        </v-row>
        <template v-if="tab === 'general'">
          <v-container class="px-4">
            <v-form ref="userForm" lazy-validation>
              <v-row class="mb-2" align="center" justify="space-between">
                <v-col cols="4">
                  <h6 class="text-subtitle-1 mb-2">{{ 'name' | localize }} *</h6>
                  <v-text-field
                    background-color="white"
                    v-model="name"
                    dense
                    outlined
                    :placeholder="'name' | localize"
                    :rules="[validationRules.required]"
                  />
                </v-col>
                <v-col cols="4">
                  <h6 class="text-subtitle-1 mb-2">{{ 'email' | localize }} *</h6>
                  <v-text-field
                    background-color="white"
                    v-model="email"
                    dense
                    outlined
                    :placeholder="'email' | localize"
                    :rules="[validationRules.required, validationRules.email]"
                  />
                </v-col>
                <v-col cols="4">
                  <h6 class="text-subtitle-1 mb-2">{{ 'booking' | localize }} {{ 'email' | localize }}</h6>
                  <v-text-field
                    background-color="white"
                    v-model="booking_email"
                    dense
                    outlined
                    :placeholder="'email' | localize"
                    :rules="[validationRules.email]"
                  />
                </v-col>
              </v-row>
              <v-row class="mb-2" align="center" justify="space-between">
                <v-col cols="6">
                  <h6 class="text-subtitle-1 mb-2">
                    {{ 'password' | localize }}
                    <template v-if="!user_id"> *</template>
                  </h6>
                  <v-text-field
                    :append-icon="password_show ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="password_show ? 'text' : 'password'"
                    background-color="white"
                    v-model="password"
                    dense
                    outlined
                    :placeholder="'enterPassword'|localize"
                    @click:append="password_show = !password_show"
                    :rules="user_id ? [] : [validationRules.required]"
                    autocomplete="new-password"
                  />
                </v-col>
                <v-col cols="6">
                  <h6 class="text-subtitle-1 mb-2">
                    {{ 'confirmPassword' | localize }}
                    <template v-if="!user_id"> *</template>
                  </h6>
                  <v-text-field
                    :append-icon="confirm_password_show ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="confirm_password_show ? 'text' : 'password'"
                    background-color="white"
                    v-model="password_confirmation"
                    dense
                    outlined
                    :placeholder="'confirmPassword' | localize"
                    :rules="[passwordConfirmationRule]"
                    @click:append="confirm_password_show = !confirm_password_show"
                    autocomplete="new-password"
                  />
                </v-col>
              </v-row>
              <v-row class="mb-2" align="center" justify="space-between">
                <v-col cols="6">
                  <v-checkbox v-model="receive_requests" class="mt-0 mb-2" :hint="'activeUserNote'|localize" persistent-hint>
                    <template v-slot:label>
                      <span class="black--text">{{ 'isActive' | localize }}</span>
                    </template>
                  </v-checkbox>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-btn
                    v-if="user_id"
                    rounded
                    depressed
                    color="error"
                    :loading="deleteLoading"
                    :disabled="saveLoading || deleteLoading"
                    @click="$refs.deleteUserDialog.open()"
                  >
                    {{ 'delete'|localize }}
                  </v-btn>
                </v-col>
                <v-col cols="6" class="d-flex justify-end">
                  <v-btn
                    rounded
                    depressed
                    color="primary"
                    :loading="saveLoading"
                    :disabled="saveLoading || deleteLoading"
                    @click="updateUser"
                  > {{ 'save'|localize }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </template>
        <template v-if="tab === 'relevancy'">
          <AllUserRelevancy/>
        </template>
      </div>
    </template>
    <SimpleDialog
      ref="deleteUserDialog"
      :title="'deleteUserTitle'|localize"
      :text="'deleteUserText'|localize"
      :accept-text="'delete'|localize"
      :decline-text="'back'|localize"
      @accept="deleteUser"
    />
  </v-container>
</template>

<script>
  import api from '@/api/api';
  import methods from '@/helpers/methods';
  import localize from '@/filters/localize';
  import validationRules from '../helpers/validationRules';
  import SimpleDialog from '@/components/common/SimpleDialog';
  import AllUserRelevancy from '@/components/AllUserRelevancy.vue';

  export default {
    name: 'CreateUser',
    components: { AllUserRelevancy, SimpleDialog },

    data() {
      return {
        company_id: this.$router.currentRoute.query.company_id,
        user_id: this.$router.currentRoute.query.user_id,
        validationRules: validationRules,
        tab: this.initialHash(),
        name: '',
        email: '',
        booking_email: '',
        receive_requests: true,
        password: '',
        password_show: false,
        password_confirmation: '',
        confirm_password_show: false,
        getLoading: true,
        saveLoading: false,
        deleteLoading: false,
      };
    },

    async mounted() {
      if (this.user_id) {
        try {
          let userData = await api.getSingleCompanyUser(this.user_id);
          this.setUserProfile(userData.data);
          this.getLoading = false;
        } catch (e) {
          this.getLoading = false;
        }
      } else {
        this.getLoading = false;
      }
    },

    computed: {
      breadcrumbsItems() {
        return [
          {
            text: localize('companies'),
            disabled: false,
            to: { name: 'admin-companies' },
          },
          {
            text: localize('editCompany'),
            disabled: false,
            to: { name: 'create-company', query: { company_id: this.company_id }, hash: '#users' },
          },
          {
            text: (this.user_id) ? localize('editUser') : localize('createUser'),
            disabled: true,
          },
        ];
      },
      passwordConfirmationRule() {
        return this.password === this.password_confirmation || localize('passwordNotMatch');
      },
      pageHeader() {
        return (this.user_id) ? this.name : localize('createUser');
      },
    },

    methods: {
      async updateUser() {
        if (this.$refs.userForm.validate()) {
          try {
            this.saveLoading = true;
            if (this.user_id) {
              await api.updateUser(this.userData());
            } else {
              await api.createCompanyUser(this.userData());
            }
            methods.showGlobalSnackbar('success', localize('done'));
            this.$router.push({ name: 'create-company', query: { company_id: this.company_id }, hash: '#users' });
          } catch (e) {
            this.saveLoading = false;
            methods.showGlobalSnackbar('error', methods.getErrors(e.response));
          }
        }
      },
      userData() {
        let userData = {};
        userData.id = this.user_id;
        userData.company_id = this.company_id;
        userData.name = this.name;
        userData.email = this.email;
        userData.receive_requests = this.receive_requests;
        if (this.booking_email) userData.booking_email = this.booking_email;
        if (this.password) userData.password = this.password;
        if (this.password_confirmation) userData.password_confirmation = this.password_confirmation;
        return userData;
      },
      setUserProfile(userData) {
        this.name = userData.name;
        this.email = userData.email;
        this.booking_email = userData.booking_email;
        this.receive_requests = userData.receive_requests
      },
      async deleteUser() {
        try {
          this.deleteLoading = true;
          await api.deleteSingleCompanyUser(this.user_id, this.company_id);
          methods.showGlobalSnackbar('success', localize('done'));
          this.$router.push({ name: 'create-company', query: { company_id: this.company_id }, hash: '#users' });
        } catch (e) {
          this.deleteLoading = false;
          methods.showGlobalSnackbar('error', methods.getErrors(e.response));
        }
      },
      initialHash() {
        const queryHash = this.$router.currentRoute.hash.slice(1);
        return queryHash ? queryHash : 'general';
      },
    },
  };
</script>
